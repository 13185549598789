import React from "react";
import About from "../sections/About";
import Gallery from "../sections/Gallery";
import GalleryAll from "../sections/GalleryAll";
import HomeIntro from "../sections/HomeIntro";
import Footer from "../sections/Footer";
import Interested from "../sections/Interested";

export default function Home() {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div>
          <HomeIntro />
        </div>
        <div>
          <About />
        </div>
        <div>
          <Gallery />
        </div>
        <div>
          <Interested />
        </div>
        <div>
          <GalleryAll/>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}
