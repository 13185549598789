import React from "react";
import DefaultH from "../components/DefaultH";
import DefaultP from "../components/DefaultP";
import ozai from "./../img/instagram.png";

export default function Footer() {
  return (
    <>
      <div
        style={{
          minWidth: "90vw",
          minHeight: "30vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          flexDirection: "column",
          border: "5px outset rgba(255,245,150, 0.5)",
        }}
      >
        <DefaultH pink="true">Interested?</DefaultH>
        <DefaultP pink="true">ohzai.management@gmail.com</DefaultP>
        <DefaultP pink="true">
          Follow me on instagram
        </DefaultP>
        <div>
          <a
            href="https://instagram.com/ohzaithegifted?igshid=YmMyMTA2M2Y="
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src={ozai}
              style={{ width: 100, height: 100 }}
              alt="instagram"
            />
          </a>
        </div>
        <a href="https://instagram.com/ohzaithegifted?igshid=YmMyMTA2M2Y="><DefaultP>@ohzaithegifted</DefaultP></a>
      </div>
    </>
  );
}
