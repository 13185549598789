import React from 'react'
import config from './../config';

export default function DefaultH(props) {
    return (
        <>
            <>
            {props.pink &&
                <h1 {...props} style={{ ...props.style, color: config.primaryColor }}>{props.children}</h1>
            }
            {!props.pink &&
                <h1 {...props} style={{ ...props.style }}>{props.children}</h1>
            }
        </>
        </>
    )
}
