import React from "react";
import config from "../config";
import { isWeb } from "./../services/windowDimensions";
import DefaultH from "./DefaultH";

export default function LogoTopNav({ purple = false, pageName = "" }) {
  return (
    <>
      {isWeb() && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: purple ? config.backgroundPurple : "",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", margin: 10 }}>
            {pageName && (
              <DefaultH
                style={{
                  color: config.primaryColor,
                  marginRight: 100,
                  textShadow: `2px 2px 0px ${config.secondaryColor}`,
                  fontSize: 26,
                }}
              >
                {pageName}
              </DefaultH>
            )}
            {/* <MenuHam /> */}
          </div>
          <DefaultH style={{ color: "rgb(255,245,150, 0.9)" }}>OHZAI</DefaultH>
          <div></div>
        </div>
      )}
      {!isWeb() && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DefaultH style={{ color: "rgb(255,245,150, 0.9)" }}>OHZAI</DefaultH>
          {/* <MenuHam /> */}
        </div>
      )}
    </>
  );
}
