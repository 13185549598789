import React from 'react';
import config from './../config';
export default function DefaultP(props) {
    return (
        <>
            {props.pink &&
                <p {...props} style={{  color: config.primaryColor, ...props.style, }}>{props.children}</p>
            }
            {!props.pink &&
                <p {...props} style={{ ...props.style }}>{props.children}</p>
            }
        </>
    )
}
