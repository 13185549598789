import React, { useRef, useState } from "react";
import Carousel from "react-gallery-carousel";
import DefaultH from "../components/DefaultH";
import DefaultP from "../components/DefaultP";
import { isWeb } from "../services/windowDimensions";

import config from "../config";

import {galleryImages} from "./images";

export default function WhyBrows() {
  const [indexCarousel, setIndexCarousel] = useState(galleryImages.f);
  const carouselRef = useRef(null);
  
  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.8)",
      }}
    >
      {isWeb() && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <DefaultH pink="true">Acquirables</DefaultH>

          <Carousel
            images={galleryImages}
            style={{
              width: "20%",
              border: "5px outset rgba(255,245,150, 0.5)",
            }}
            index={indexCarousel}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
            onIndexChange={({ curIndex }) => {
              setIndexCarousel(curIndex);
              carouselRef.current.goToIndex(curIndex);
            }}
          ></Carousel>
          <Carousel
            ref={carouselRef}
            isLoop={false}
            hasMediaButton={false}
            hasThumbnails={false}
            index={indexCarousel}
            shouldSwipeOnMouse={false} // for selecting text
            shouldMinimizeOnSwipeDown={false} // for vertical overflow scrolling
            isMaximized={false}
            hasSizeButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnailsAtMax={false}
            thumbnailWidth={"15%"}
            thumbnailHeight={"15%"}
            shouldMaximizeOnClick={false}
            shouldMinimizeOnClick={false}
            style={{
              userSelect: "text",
              width: "20%",
              margin: "1em",
              padding: "1em",
              borderRadius: 10,
              color: config.primaryColor,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            {galleryImages.map((image, index) => {
              
              return (
                <div key={index} style={{ textAlign: "center" }}>
                  <h3>{image.title}</h3>
                  <span style={{ fontSize: 12 }}>
                    {image.type} {image.size}cm
                  </span>
                  <DefaultP style={{ fontSize: 14 }}>{image.desc}</DefaultP>
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
      {!isWeb() && (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80vw",
            border: "5px outset rgba(255,245,150, 0.5)",
          }}
        >
          <DefaultH pink="true">Acquirables</DefaultH>

          <Carousel
            images={galleryImages}
            style={{ width: "90%" }}
            index={indexCarousel}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
            onIndexChange={({ curIndex }) => {
              setIndexCarousel(curIndex);
              carouselRef.current.goToIndex(curIndex);
            }}
          ></Carousel>
          <Carousel
            ref={carouselRef}
            isLoop={false}
            hasMediaButton={false}
            hasThumbnails={false}
            index={indexCarousel}
            shouldSwipeOnMouse={false} // for selecting text
            shouldMinimizeOnSwipeDown={false} // for vertical overflow scrolling
            isMaximized={false}
            hasSizeButton={false}
            hasIndexBoard={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasThumbnailsAtMax={false}
            thumbnailWidth={"15%"}
            thumbnailHeight={"15%"}
            shouldMaximizeOnClick={false}
            shouldMinimizeOnClick={false}
            style={{
              userSelect: "text",
              width: "90%",
              margin: "1em",
              padding: "1em",
              borderRadius: 10,
              color: config.primaryColor,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            {galleryImages.map((image) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h3>{image.title}</h3>
                  <span style={{ fontSize: 12 }}>
                    {image.type} {image.size}cm
                  </span>
                  <DefaultP style={{ fontSize: 14 }}>{image.desc}</DefaultP>
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
}
