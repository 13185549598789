import { motion } from "framer-motion";
import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import DefaultH from "./../components/DefaultH";
import { isWeb } from "./../services/windowDimensions";
import images from "./images";
export default function GalleryIntro(props) {
  const startVar = Math.floor(Math.random() * (images.length - 1));
  return (
    <>
      {isWeb() && (
        <>
          {props.title && (
            <DefaultH pink="true" style={{ textAlign: "center" }}>
              {props.title}
            </DefaultH>
          )}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            className="carousel-container"
            style={{
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              minWidth: "80vw",
            }}
          >
            <CarouselContainer>
              <Carousel
                images={images}
                style={{
                  height: "50vh",
                  width: "20vw",
                  zIndex: -1,
                }}
                isAutoPlaying={true}
                autoPlayInterval={6000}
                transitionDurationMin={3 * 1000}
                index={startVar}
                isMaximized={false}
                hasSizeButton={false}
                hasMediaButton={false}
                hasIndexBoard={false}
                hasLeftButton={false}
                hasRightButton={false}
                hasThumbnails={false}
                shouldMaximizeOnClick={false}
                shouldMinimizeOnClick={false}
              />
            </CarouselContainer>
            <CarouselContainer>
              <Carousel
                images={images}
                autoPlayInterval={6000}
                transitionDurationMin={3 * 1000}
                style={{
                  height: "60vh",
                  width: "25vw",
                }}
                hasCaptions={"bottom"}
                isAutoPlaying={true}
                index={startVar + 1}
                isMaximized={false}
                hasSizeButton={false}
                hasMediaButton={false}
                hasIndexBoard={false}
                hasLeftButton={false}
                hasRightButton={false}
                hasThumbnails={false}
                shouldMaximizeOnClick={false}
                shouldMinimizeOnClick={false}
              ></Carousel>
            </CarouselContainer>
            <CarouselContainer>
              <Carousel
                images={images}
                style={{ height: "50vh", width: "20vw", zIndex: -1 }}
                autoPlayInterval={6000}
                transitionDurationMin={3 * 1000}
                isAutoPlaying={true}
                index={startVar + 2}
                isMaximized={false}
                hasSizeButton={false}
                hasMediaButton={false}
                hasIndexBoard={false}
                hasLeftButton={false}
                hasRightButton={false}
                hasThumbnails={false}
                shouldMaximizeOnClick={false}
                shouldMinimizeOnClick={false}
              >
                <h1>What is this</h1>
              </Carousel>
            </CarouselContainer>
          </motion.div>
        </>
      )}
      {!isWeb() && (
        <>
          <motion.div
            initial={{ opacity: 0, scale: 0.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 5 }}
            style={{
              minWidth: "50vw",
              minHeight: "40vh",
              maxHeight: "80vh",
              display: "grid",
              gridTemplateColumns: "1fr",
              border: "5px outset rgba(255,245,150, 0.5)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Carousel
              images={images}
              style={{
                height: "60vh",
                width: "80vw",
                zIndex: -1,
              }}
              isAutoPlaying={true}
              autoPlayInterval={7000}
              transitionDurationMin={3 * 1000}
              index={startVar}
              isMaximized={false}
              hasSizeButton={false}
              hasMediaButton={false}
              hasIndexBoard={false}
              hasLeftButton={false}
              hasRightButton={false}
              hasCaptionsAtMax="top"
              hasDotButtonsAtMax="bottom"
              hasThumbnails={false}
              hasThumbnailsAtMax={true}
              thumbnailWidth={"15%"}
              thumbnailHeight={"15%"}
              shouldMaximizeOnClick={true}
              shouldMinimizeOnClick={true}
            />
          </motion.div>
        </>
      )}
    </>
  );
}
const CarouselContainer = (props) => {
  return (
    <div style={{ border: "5px outset rgba(255,245,150, 0.5)" }}>
      {props.children}
    </div>
  );
};
