import React from "react";
import DefaultH from "../components/DefaultH";
import DefaultP from "../components/DefaultP";
import ozai from "./../img/ozai2.jpeg";
import netherlands from './../img/netherlands.jpg'
import korsou from './../img/korsou.jpg'

export default function MotivationSection2() {
  return (
    <div style={{ minHeight: "50vh", minWidth: "100vw" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "5px outset rgba(255,245,150, 0.5)",
          minHeight: "50vh",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
      >
        <div>
          <DefaultH style={{ color: "rgb(255,245,150)" }}>
            Inside My Mind
          </DefaultH>
          <h3 style={{ color: "rgb(255,245,150)" }}>OHZAI</h3>
          <DefaultP style={{ color: "rgb(255,245,150)" }}>
            Artist born in Curacao 🇨🇼 based in Netherlands 🇳🇱
          </DefaultP>
          <div>
            <img
              style={{
                margin: 5,
                width: 200,
                height: 200,
                padding: 20,
                border: "5px outset rgba(255,245,150, 0.5)",
              }}
              src={korsou}
              alt="test"
            />
            <img
              style={{
                margin: 5,
                width: 200,
                height: 200,
                padding: 20,
                border: "5px outset rgba(255,245,150, 0.5)",
              }}
              src={ozai}
              alt="test"
            />
<img
              style={{
                margin: 5,
                width: 200,
                height: 200,
                padding: 20,
                border: "5px outset rgba(255,245,150, 0.5)",
              }}
              src={netherlands}
              alt="test"
            />            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DefaultP pink="true" style={{ width: "50%" }}>
                <i>
                  There was a time when I didn’t understand at all what the
                  meaning of art was. Ironically I didn’t know how to express myself
                  either. So many feeling accumulated along the years and little
                  did I know how to handle them. Until the day I held a brush in
                  my hand and there it was just a blank white canvas simply
                  empty. My whole headspace went silent and all I could do
                  was express my thoughts/feelings/vibe through this paint and
                  brush on the blank canvas. That was the day it all made sense and I knew I wanted
                  to become an Artist.
                </i>
              </DefaultP>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
