import React from "react";
import videoMob from "../../img/background_mobile.mp4";
import videoWeb from "../../img/background_movie.mp4";
import { isWeb } from "./../../services/windowDimensions";

export default function BackgroundVid() {
  const BackgroundVid = isWeb() ? videoWeb : videoMob;
  return (
    <div
      style={{
        position: "fixed",
        maxWidth: "100%",
        maxHeight: "100%",
        minWidth: "100vw",
        minHeight: "100vh",
        zIndex: -1,
        backgroundColor: "rgba(0,0,0,1)",
      }}
    >
      {/* Credits Rostislav Uzunov */}
      <video width="100%" loop muted autoPlay>
        <source src={BackgroundVid}></source>
      </video>
    </div>
  );
}
