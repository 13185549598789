import pic1 from "../img/1.jpg";
import pic2 from "../img/2.png";
import pic3 from "../img/3.jpg";
import pic4 from "../img/4.jpg";
import pic5 from "../img/5.jpg";
import pic6 from "../img/6.jpg";
import pic7 from "../img/7.jpg";
import pic8 from "../img/8.jpg";
import pic9 from "../img/9.jpg";
import pic10 from "../img/10.jpg";
import pic11 from "../img/11.jpg";
import pic12 from "../img/12.jpg";
import pic13 from "../img/13.jpg";
import pic14 from "../img/14.jpg";
import pic15 from "../img/15.jpg";
import pic16 from "../img/16.png";
import pic17 from "../img/17.png";
import pic18 from "../img/18.png";
import pic19 from "../img/19.png";
import pic20 from "../img/20.png";
import pic21 from "../img/21.png";
import pic22 from "../img/22.png";
import pic23 from "../img/23.png";
import pic24 from "../img/24.png";
import pic25 from "../img/25.png";
import pic26 from "../img/26.png";


export const galleryImages = 
     [
        // {
        //   pic: pic6,
        //   title: "Take Over",
        //   type: "Acrylic on canvas",
        //   desc: "",
        //   size: "24x30",
        // },
        {
          pic: pic7,
          title: "Teleporting",
          type: "Acrylic on canvas",
          desc: "",
          size: "24x30",
        },
        {
          pic: pic9,
          title: "Weeee~",
          type: "Acrylic on canvas",
          desc: "",
          size: "40x50",
        },
        {
          pic: pic10,
          title: "Boiling Fury",
          type: "Acrylic on canvas",
          desc: "",
          size: "30x40",
        },
        {
            pic: pic11,
            title: "Barrier",
            type: "Oil on canvas",
            desc: "",
            size: "24x30",
          },
        {
          pic: pic12,
          title: "Daymare",
          type: "Oil on canvas",
          desc: "",
          size: "40x50",
        },
        {
          pic: pic14,
          title: "Trespassing Consequences",
          type: "Acrylic on canvas",
          desc: "",
          size: "24x30",
        },
        {
            pic: pic13,
            title: "Pick your poison",
            type: "Oil on canvas",
            desc: "",
            size: "50x70",
          },
          {
            pic: pic16,
            title: "Sinners Pit",
            type: "Acrylic on canvas",
            desc: "",
            size: "24x30",
          },
          {
            pic: pic17,
            title: "Perspective Mirror",
            type: "Acrylic on canvas",
            desc: "",
            size: "24x30",
          },
          {
            pic: pic24,
            title: "Manipulative Whispers",
            type: "Acrylic on canvas",
            desc: "",
            size: "24x30",
          },
          {
            pic: pic20,
            title: "Fishing for Light",
            type: "Acrylic on canvas",
            desc: "",
            size: "40x50",
          },
          {
            pic: pic19,
            title: "Portal of Chaos",
            type: "Acrylic on canvas",
            desc: "",
            size: "60x80",
          },
      ].map((image) => ({
        src: image.pic,
        alt: image.alt,
        type: image.type,
        title: image.title,
        desc: image.desc,
        size: image.size,
        gallery : image.gallery
      }));




      




export default [
  {
    pic: pic1,
    title: "The Arrival",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic2,
    title: "Touch of Motion",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic3,
    title: "Passport photo",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic4,
    title: "Another look",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic5,
    title: "Decaying",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic6,
    title: "Decaying",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic7,
    title: "Teleporting",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic8,
    title: "Chalice",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic9,
    title: "Weeee",
    type: "Acrylic on canvas",
    desc: "",
    size: "40x50",
  },
  {
    pic: pic10,
    title: "Boiling Fury",
    type: "Acrylic on canvas",
    desc: "",
    size: "30x40",
  },
  {
    pic: pic11,
    title: "Barrier",
    type: "Oil on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic12,
    title: "Daymare",
    type: "Oil on canvas",
    desc: "",
    size: "40x50",
  },
  {
    pic: pic13,
    title: "Choose Wisely",
    type: "Oil on canvas",
    desc: "",
    size: "50x70",
  },
  {
    pic: pic14,
    title: "Trespassing Consequences",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
//   {
//     pic: pic15,
//     title: "Portal of Sensation",
//     type: "Acrylic on canvas",
//     desc: "",
//     size: "60x80",
//   },
  {
    pic: pic16,
    title: "Sinners Pit",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic17,
    title: "Perspective Mirror",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic18,
    title: "Birth Fontain",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic19,
    title: "Portal of Chaos",
    type: "Acrylic on canvas",
    desc: "",
    size: "60x80",
  },
  {
    pic: pic20,
    title: "Fishing for Light",
    type: "Acrylic on canvas",
    desc: "",
    size: "40x50",
  },
  {
    pic: pic21,
    title: "It's a Secret",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic22,
    title: "Hiding from the Rainbow",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic23,
    title: "Functional but Unbalanced",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic24,
    title: "Manipulative Whispers",
    type: "Acrylic on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic25,
    title: "Much noise little focus",
    type: "Mix medium on canvas",
    desc: "",
    size: "24x30",
  },
  {
    pic: pic26,
    title: "21st century",
    type: "Mix medium on canvas",
    desc: "",
    size: "60x80",
  },
].map((image) => ({
  src: image.pic,
  alt: image.alt,
  type: image.type,
  title: image.title,
  desc: image.desc,
  size: image.size,
  gallery : image.gallery
}));
