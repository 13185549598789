import AOS from 'aos';
import Home from './Home';

function App() {
  AOS.init();
  return (
    <Home />
  );
}

export default App;
