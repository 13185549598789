import React from "react";
import BackgroundVid from "./../components/background/BackgroundVid";
import HomeCenter from "./../components/HomeCenter";
import LogoTopNav from "./../components/LogoTopNav";

export default function HomeIntro() {
  return (
    <div style={{ minWidth: "100vw", minHeight: "100vh" }}>
      <BackgroundVid />
      <LogoTopNav />
      <HomeCenter />
    </div>
  );
}
