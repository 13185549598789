import React, { Component } from 'react'
import DefaultP from '../components/DefaultP'
import config from '../config'

export default class Footer extends Component {
  render() {
    return (
        <div
        style={{
          minWidth: "100vw",
          minHeight: 40,
          backgroundColor: config.primaryColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DefaultP style={{ fontWeight: "bold" }}>
          @OHZAI {new Date().getFullYear()}
        </DefaultP>
      </div>
    )
  }
}
